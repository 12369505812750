import { Luminous, LuminousGallery } from 'luminous-lightbox';
import Swiper from 'swiper/bundle';

// const luminousOpt = {
// 	caption: (trigger) => {
// 		return trigger.querySelector('img').getAttribute('alt');
// 	},
// }
// const luminousGalleryContent = document.querySelectorAll('.gallery__slider-link');
// if( luminousGalleryContent.length > 0 ) {
// 	new LuminousGallery(luminousGalleryContent, {}, luminousOpt);
// }

const swiper = new Swiper(".page-gallery", {
	navigation: {
		nextEl: ".swiper-button-next",
		prevEl: ".swiper-button-prev"
	},
});